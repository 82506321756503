import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('BomType?page=' + page,  {params});
    }, 
    show(id) {
        return Service().get('BomType/show/'+id);
    },    
    create(params) {
        return Service().post('BomType/add_type', params);
    },
    // update(id, params) {
    //     return Service().post('BomType/update_type/'+id, params);
    // },
    delete(code) {
        return Service().post('BomType/delete_type/'+code);
    },
    add_char_type(params) {
        return Service().post('BomType/add_char_value', params);
    },
    get_type_characteristic(page, bom_type_code, params) {
        return Service().get('BomType/get_type_characteristic/'+bom_type_code+'?page='+page, {params});
    },
    update_char_type(id, params) {
        return Service().post('BomType/update_char_type/'+id, params);
    },
    delete_char_type(code) {
        return Service().post('BomType/delete_char_type/'+code);
    },
    
}