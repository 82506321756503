import Service from '@/helpers/service'

export default {
  get(page, params) {
    return Service().get("BomCharacteristicMaster?page=" + page, { params });
  },
  create(params) {
    return Service().post("BomCharacteristicMaster/add_characteristic", params);
  },
  update(bom_characteristic_code, params) {
    return Service().post(
      "BomCharacteristicMaster/update_characteristic/" +bom_characteristic_code,params);
  },
  delete(id) {
    return Service().post(
      "BomCharacteristicMaster/delete_characteristic/" + id
    );
  }
};