<template>
    <div>
        <div>
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="row">
                    <!-- TYPE -->
                    <div class="col-md-6">
                        <div id="cardBomType" class="card mb-4">
                            <div class="card-header mt--4" id="table">
                                <div class="row">
                                    <div class="col-6">
                                        <h4>{{ tt('type') }}</h4>
                                    </div>
                                    <div class="col-6 text-right">
                                        <base-button size="sm" type="transparant" @click="create"><i class="fa fa-plus-square"></i></base-button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showTable">
                                <el-table 
                                    id="tableType" 
                                    height="400px" 
                                    class="table-responsive table-flush " 
                                    header-row-class-name="thead-light" 
                                    :data="table.data" 
                                    v-if="!onLoad"
                                    highlight-current-row
                                    @current-change="handleCurrentChange"
                                    >
                                    <!-- <el-table-column type="selection" width="20"></el-table-column> -->
                                    <el-table-column :label="tt('type')" :prop="tt('type')" min-width="100px" sortable>
                                        <template v-slot="{row}">
                                            {{row.bom_type_name}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="action" width="80">
                                        <template v-slot="{row}">
                                            <i class="fa fa-trash text-danger" @click="remove(row.bom_type_code)"></i>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <page-loading v-else/>
                            </div>
                            
                            <div class="text-center" style="height: 400px" v-else>
                                <br><br>
                                <img src="/assets/smartcat/data_is_empty.png" alt="Data Is Empty" width="350px">
                                <p class="text-muted">{{ tt('please_add_data') }}</p>
                            </div>  
                            <div class="card-footer pb-0 ">   
                                <span class="float-left">
                                    <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                                </span>                             
                                <!-- <span class="float-right">
                                    {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                                </span> -->
                            </div>
                        </div>
                    </div>

                    <!-- VIEW TYPE CHARACTERISTIC -->
                    <div class="col-md-6">                        
                        <div id="cardBomType" class="card mb-4">
                            <div class="card-header mt--4" style="padding-bottom:2px">
                                <div class="row">
                                    <div class="col-4">
                                        <h4>{{ tt('characteristic') }}</h4>
                                    </div>
                                    <div class="col-5">
                                        <base-input input-classes="form-control-sm text-center border-radius-20" v-model="tableTypeCharacteristic.search" :placeholder="tt('search')" v-on:keyup="filterTypeChar" :disabled="!btnAddTypeChar"/>
                                    </div>
                                    <div class="col-3 text-right">
                                        <base-button size="sm" type="default" @click="createCharType" :disabled="!btnAddTypeChar">{{ tt('add') }}</base-button>
                                    </div>
                                </div>
                            </div>
                            <div>                                
                                <el-table id="tableType" height="400px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="tableTypeCharacteristic.data" v-if="!onLoadTypeChar">
                                    <el-table-column :label="tt('characteristic_name')" :prop="tt('characteristic_name')" min-width="250px" sortable>
                                        <template v-slot="{row}">
                                            {{row.bom_characteristic_name}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="action" width="80">
                                        <template v-slot="{row}">
                                            <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true">
                                                <span class="btn btn-sm btn-icon-only text-light">
                                                    <i class="fas fa-ellipsis-v mt-2"></i>
                                                </span>
                                                <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                    <el-dropdown-item :command="{action:'editTypeCharacteristic', data:tableTypeCharacteristic.data.map(function(d) { return d['type_characteristic_value']; }).indexOf(row.type_characteristic_value)}">{{ tt('edit') }}</el-dropdown-item>
                                                    <el-dropdown-item :command="{action:'removeTypeCharacteristic', data:row.bom_type_characteristic_code}">{{ tt('delete') }}</el-dropdown-item>                                        
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <page-loading v-else/>
                            </div>  
                            <div class="card-footer pb-0 ">   
                                <span class="float-left">
                                    <base-pagination :page-count="tableTypeCharacteristic.page.last_page" v-model="tableTypeCharacteristic.page.current_page" @click.native="changePageTypeChar(tableTypeCharacteristic.page.current_page)" size="sm"></base-pagination>
                                </span>                             
                                <span class="float-right">
                                    {{ tt('page_info', {size: (tableTypeCharacteristic.page.current_page - 1) * tableTypeCharacteristic.page.per_page + tableTypeCharacteristic.data.length, total: tableTypeCharacteristic.total}) }}
                                </span>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>

            <!-- MODAL TYPE -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('name') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('type')" :placeholder="tt('type')" v-model="bomType.bom_type_name" rules="required" @input="bomType.bom_type_name = bomType.bom_type_name.toUpperCase()"></base-input>
                        <br>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- MODAL TYPE CHARACTERISTIC-->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formTypeChar.show">
                    <template slot="header">
                        <h5 class="modal-title">{{formTypeChar.title}}</h5>
                    </template>
                    <div>
                        <label class="form-control-label">{{ tt('characteristic_master') }} <span class="text-danger">*</span></label>
                        <div class="row">
                            <el-select class="form-control-sm col-md-12 mb-4 " v-model="typeCharacteristic.bom_characteristic_code" :placeholder="tt('choose_characteristic_master')" :remote-method="getBomCharacteristicMaster" :loading="loading" filterable remote reserve-keyword clearable>
                                <el-option v-for="bcm in bomCharacteristicMaster" :key="bcm.bom_characteristic_name" :label="bcm.bom_characteristic_name" :value="bcm.bom_characteristic_code"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formTypeChar.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveTypeChar" :disabled="btnSaveTypeChar.onLoading || invalid">
                            <span v-if="btnSaveTypeChar.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="formTypeChar.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

        </div>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import bomType from '@/services/bom/bomType.service';
    import bomCharacteristicMaster from '@/services/bom/bomCharacteristicMaster.service';

    export default {    
        data() {
            return {     
                showTable: true,
                onLoad: false,    
                onLoadTypeChar: false,    
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },       
                btnSaveTypeChar: {
                    onLoading: false
                },     
                btnAdd: false,
                btnEdit: false,
                btnAddTypeChar: false,
                searchTypeChar: false,
                form: {
                    add: true,
                    title: this.tt('add_type'),
                    show: false,
                },
                formTypeChar: {
                    add: true,
                    title: this.tt('add_type_characteristic'),
                    show: false,
                },
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },
                tableTypeCharacteristic: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },            
                errorMessage: null, 
                links: [],  
                bomType: {},
                bomCharacteristicMaster: {},
                typeCharacteristic: {
                    bom_type_characteristic_code: '',
                    bom_characteristic_code: '',
                    bom_type_code: '',
                    sequence: '',
                    bom_characteristic_name:''
                },
                currentRow: {
                    index: null
                },
                loading:false,
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.firstLoad()
            this.getBomCharacteristicMaster()
        },
        methods: {
            handleCurrentChange(val, index) {
                this.typeCharacteristic.bom_type_code = val.bom_type_code;
                this.currentRow = val;
                this.currentRow.index = index;
                this.btnAddTypeChar = true;
                this.onLoadTypeChar = true;
                this.getTypeCharacteristic();                
            },
            getBomCharacteristicMaster(query) {
                if (query.length >= 1) {
                    clearTimeout(this.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.loadTimeout = setTimeout(() => {
                        Api(context, bomCharacteristicMaster.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                            context.bomCharacteristicMaster = response.data.data.data.data;  
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.bomCharacteristicMaster = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            get() { 
                let context = this;               
                Api(context, bomType.get(this.table.page.current_page)).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;
                    context.table.page  = response.data.data.data;   
                    context.onLoad = false;          
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                }).onFinish(function() {
                        context.tableTypeCharacteristic.data = []; 
                }).call()
            },
            getTypeCharacteristic() {
                let context = this;
                let search = '';
                let index = [];
                context.onLoadTypeChar = true;
  
                Api(context, bomType.get_type_characteristic((this.tableTypeCharacteristic.page.current_page ? this.tableTypeCharacteristic.page.current_page : 1), context.currentRow.bom_type_code, {search_type_characteristic: context.tableTypeCharacteristic.search})).onSuccess(response => {
                    this.tableTypeCharacteristic.total = response.data.data.data.total;
                    this.tableTypeCharacteristic.data = response.data.data.data.data;  
                    this.tableTypeCharacteristic.page = response.data.data.data;      
                }).onError(function(error) {  
                    if (error.response.status == 404) {
                        if (error.response.data.message == 'Type Characteristic Is Empty') {             
                            context.errorMessage = null;
                            context.tableTypeCharacteristic.data = []
                        } else if (error.response.data.message == 'Validation Error') {
                            context.errorMessage = error.response.data;
                        }
                    }
                }).onFinish(function() {                                        
                    context.onLoadTypeChar = false;
                }).call()
            },
            firstLoad() {
                this.onLoad = true;
                this.get()
            },
            get() { 
                let context = this;               
                Api(context, bomType.get(this.table.page.current_page)).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;
                    context.table.page  = response.data.data.data;   
                    context.onLoad = false;          
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                }).onFinish(function() {
                        context.tableTypeCharacteristic.data = []; 
                }).call()
            },
            filterTypeChar() {
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {                    
                    this.getTypeCharacteristic()
                }, 100);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'editTypeCharacteristic':
                            this.editTypeCharacteristic(command.data);
                        break;
                    case 'removeTypeCharacteristic':
                            this.removeTypeCharacteristic(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_type');
                this.form.show = true;
                this.bomType = {
                    'bom_type_name': '',
                };
            }, 
            createCharType() {
                this.typeCharacteristic.bom_characteristic_name= this.bomCharacteristicMaster.bom_characteristic_name;
                this.formTypeChar.add = true;
                this.formTypeChar.title = this.tt('add_characteristic');
                this.formTypeChar.show = true;
            },
            editTypeCharacteristic(index) {
                let context = this;
                context.typeCharacteristic = {
                    id: context.tableTypeCharacteristic.data[index].id,
                    bom_characteristic_code: context.tableTypeCharacteristic.data[index].type_characteristic_value,
                    bom_characteristic_name: context.tableTypeCharacteristic.data[index].type_characteristic_value,
                    bom_type_characteristic_code: context.tableTypeCharacteristic.data[index].type_characteristic_value,
                };               
                context.formTypeChar.add = false;
                context.formTypeChar.title = context.tt('edit');
                context.formTypeChar.show = true;                          
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;

                // context.get();
                if (this.form.add) {
                    api = Api(context, bomType.create({'bom_type_name':this.bomType.bom_type_name}));
                } else {
                    api = Api(context, bomType.update(this.bomType.id, this.bomType));
                }
                api.onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                 }).onError(function(error) {
                     context.$notify({
                        message: error.response.data.message,                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }).onFinish(function() {
                    context.get();
                    context.btnSave.onLoading = false;   
                    context.form.show = false;
                })
                .call();
            },
            saveTypeChar() {                      
                let context = this;
                let api = null;
                context.btnSaveTypeChar.onLoading = true;

                context.typeCharacteristic.bom_type_characteristic_code = context.currentRow.bom_type_characteristic_code; 
                let data = {
                    bom_type_code : this.typeCharacteristic.bom_type_code,
                    bom_characteristic_code: this.typeCharacteristic.bom_characteristic_code,
                    bom_type_characteristic_code: this.typeCharacteristic.bom_type_characteristic_code,
                }                      
                if (context.formTypeChar.add) {
                    api = Api(context, bomType.add_char_type(data));
                } else {{
                    api = Api(context, bomType.update_char_type(context.typeCharacteristic.id, context.typeCharacteristic));
                }}
                api.onSuccess(function(response) {
                    context.getTypeCharacteristic();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formTypeChar.show = false;
                }).onError(function(error) {
                    context.errorMessage = error.response.data;   
                }).onFinish(function() {
                    context.btnSaveTypeChar.onLoading = false;   
                })
                .call();
            },
            remove(code) {
                let context = this;
                context.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        context.onLoad = true;
                        Api(context, bomType.delete(code)).onSuccess(function(response) {
                            context.get();
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                        }).onError((error) =>{
                           context.$notify({
                                message: error.response.data.message,
                                type: 'danger',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                        }).onFinish(function() {
                            context.onLoad = false;   
                        }).call();
                    }
                })
            },
            removeTypeCharacteristic(code) {
                let context = this;
                context.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        context.onLoadTypeChar = true;
                        Api(context, bomType.delete_char_type(code)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.getTypeCharacteristic();
                        }).onFinish(function() {
                            context.onLoadTypeChar = false;
                        }).call();
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            changePageTypeChar(page) {
                let context = this;
                context.onLoadTypeChar = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.getTypeCharacteristic()
                }, 100);
            }
        }   
    };
</script>
<style>
    .selected-row{
        background-color: #ecf5ff;
        color:white;
    }
    div.el-table tbody td, div.el-table thead th {
        padding: 0rem;
    }
    .form-control-sm{
        height:25px
    }
    .filter.mb--1:hover{
        color: #e9ecef;
        cursor:pointer;
    }
    .el-table__body tr.current-row>td{
        background-color: #ffe391 !important;
    }
    /*#cardBomType.card .table td, #cardBomType.card .table th, #cardBomType.card .el-table td, #cardBomType.card .el-table th{
        padding-left:0.1rem;
        padding-right:0.1rem;
    }*/
    #tableType.table thead th, #tableType.el-table thead th{
        letter-spacing:0px;
        font-size: 0.59rem;
    }
    #tableType.table tbody td, #tableType.el-table tbody td{
        letter-spacing:0px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        /* margin-top: 30%; */
        /*font-size: 0.69rem;*/
    }
    .el-autocomplete-suggestion li{
        overflow:visible;
    }
</style>
